import { Input, InputRef, Popover, Tooltip } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import Picker from '@emoji-mart/react';
import { IGridListBlockDetail, IProjectState } from 'models/interface';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useProjectDashboardStore } from 'stores';
import { isEmptyString } from 'utils/commonFunctions';
import BrowserStorageService from 'services/browserStorage.service';
interface CustomNumberFieldProps {
  value: string;
  disabled?: boolean;
  configuration?: any;
  taskDetail: IGridListBlockDetail;
  onChange: (value: any) => void;
}
const GridCustomTextField: React.FC<CustomNumberFieldProps> = (props) => {
  let {
    value: initialValue,
    disabled = false,
    configuration,
    taskDetail,
    onChange,
  } = props;
  const [value, setValue] = useState<string | null>(initialValue);
  const [editing, setEditing] = useState(false);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const shortTextInputRef = useRef<InputRef>(null);
  const emojiButtonRef = useRef<HTMLSpanElement>(null);
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [emojiCount, setEmojiCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const MAX_LENGTH = 30;
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { hasPermissions } = useRbac();
  const { messagingCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const browserStorage = BrowserStorageService.getInstance();
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !emojiButtonRef.current?.contains(event.target as Node) &&
        !popoverContentRef.current?.contains(event.target as Node) &&
        shortTextInputRef.current?.input
      ) {
        updateExistingTask();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPickerVisible, value]);
  const updateExistingTask = async () => {
    setEditing(false);
    const currentValue = value;
    if (initialValue === currentValue) {
      setValue(initialValue);
      return;
    }
    if (!initialValue && !currentValue) {
      setValue('');
      return;
    }
    onChange(currentValue);
  };
  const enableEditing = () => {
    setEditing(true);
  };
  const countEmojisAndLetters = (text: string) => {
    let emojiRegex =
      /(?:\uD83D[\uDC00-\uDFFF]|\uD83C[\uDC00-\uDFFF]|\uD83E[\uDD00-\uDFFF]|\u2600-\u26FF|\u2700-\u27BF|[\u200D\uFE0F])/g;
    let letterRegex: any = /[a-zA-Z]/g;
    let emojiMatches = text.match(emojiRegex) || [];
    const nonEmojiChars = text.replace(emojiRegex, '');
    let emojiCount = emojiMatches.length;
    let letterCount = nonEmojiChars.length;
    let totalCount = letterCount + emojiCount;
    let withoutEmojiCount = totalCount - emojiCount;
    setEmojiCount(emojiCount);
    setTotalCount(letterCount);
    return withoutEmojiCount;
  };
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const totalCharacterCount = countEmojisAndLetters(input);
    if (totalCharacterCount <= MAX_LENGTH) {
      setValue(input);
    }
  };
  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  );
  const handleEmojiClick = () => {
    setEmojiPickerVisible(!emojiPickerVisible);
  };
  const selectEmoji = (emoji: any) => {
    if (shortTextInputRef.current?.input) {
      const inputElement = shortTextInputRef.current.input;
      const cursorStart = inputElement.selectionStart || 0;
      const cursorEnd = inputElement.selectionEnd || 0;
      if (cursorStart === null || cursorEnd === null) return;
      const currentValue = value || '';
      const beforeCursor = currentValue.slice(0, cursorStart);
      const afterCursor = currentValue.slice(cursorEnd);
      const newValue = beforeCursor + emoji.native + afterCursor;
      setValue(newValue);
      requestAnimationFrame(() => {
        inputElement.focus();
        let newCursorPosition: number;
        if (cursorEnd === currentValue.length) {
          newCursorPosition = beforeCursor.length + emoji.native.length;
          setTimeout(() => {
            inputElement.scrollLeft = inputElement.scrollWidth;
          }, 0);
          inputElement.scrollLeft = inputElement.scrollWidth;
        } else {
          newCursorPosition = beforeCursor.length + emoji.native.length;
        }
        setTimeout(() => {
          inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
      });
      setEmojiPickerVisible(false);
    }
  };
  const renderInput = allowedToEdit ? (
    <div ref={containerRef} onMouseDown={(e) => e.stopPropagation()}>
      <Popover
        placement="bottomLeft"
        trigger={'click'}
        content={
          <div onMouseDown={(e) => e.stopPropagation()}>
            <Picker
              ref={popoverContentRef}
              onEmojiSelect={selectEmoji}
              theme="dark"
              locale={browserStorage.getLocalization().language}
            />
          </div>
        }
        onOpenChange={handleEmojiClick}
        open={emojiPickerVisible}
        overlayClassName={'messageThreadClass'}
        arrow={false}
      >
        <Tooltip
          title={messagingCmsData?.lbl_extension_tooltip['4']}
          placement="bottom"
          arrow={true}
          color={'#2E364C'}
        >
          <span
            ref={emojiButtonRef}
            className={`cmnIcon emoji ${emojiPickerVisible ? 'is-active' : ''}`}
            onClick={handleEmojiClick}
            onKeyDown={() => {}}
          ></span>
        </Tooltip>
      </Popover>
      <Input
        ref={shortTextInputRef}
        autoFocus={editing}
        // allowClear
        value={value ?? ''}
        onFocus={() => setEditing(true)}
        disabled={disabled}
        onChange={onValueChange}
        onPressEnter={updateExistingTask}
        style={{
          boxShadow: 'none',
          height: 32,
        }}
        placeholder={
          customfieldCmsData?.lbl_custom_text_field_placeholders
            ?.lbl_short_text_field_placeholder
        }
      />
    </div>
  ) : null;
  return !editing && !isEmptyString(value) ? (
    <div
      role="button"
      className={`taskNameBlk ${
        disabled ? 'cursorDisabled' : 'cursorPointer'
      } `}
      onClick={() => {
        allowedToEdit && !disabled && enableEditing();
      }}
    >
      <Tooltip
        color="#2E364C"
        title={value}
        destroyTooltipOnHide
        placement="left"
      >
        <span className="simpleTaskListTaskName">{value}</span>
      </Tooltip>
    </div>
  ) : (
    renderInput
  );
};
export default GridCustomTextField;
